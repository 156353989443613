.website-header {
  margin-left: -5.0%;
  margin-top: -0.5%;
  margin-right: -5.0%;
  padding-top: 0.5%;
  padding-left: 20%;
  padding-bottom: 1%;
  background-color: #363636;
  color: lightgray;
}

.terminalText {
  font-size: 75%;
  line-height: 50%;
}

@media screen and (max-width: 600px) {
  .website-header {
    display: none;
  }
}
