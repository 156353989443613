.projects {
  padding-top: 6%;
  font-size: 100%;
}

.projects h1 {
  margin-left: 10%;
  margin-right: 10%;
}

.projects-container {
  display:flex;
  flex-direction: row;
  align-items: flex-start;
  flex-wrap: wrap;
  justify-content: center;
}

.project {
  width: 40%;
  margin-right: 2%;
  margin-top: 2%;
  padding: 10px 40px;
}

@media screen and (max-width: 600px) {
  .project {
    width: 90%
  }
}

.date {
  text-align: right;
}
