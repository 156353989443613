.about {
  padding-top: 6%;
  font-size: 100%;
  margin-left: 20%;
  margin-right: 20%;
  display:flex;
  justify-content:center;
  flex-direction: row;
  align-items: flex-start;
}

.greeting {
  flex: 60%;
  font-size: 100%;
}

@media screen and (max-width: 600px) {
  .about {
    margin-left: 10%;
    margin-right: 10%
  }
}

.greeting a {
  text-decoration: none;
  color: #4560db;
  font-weight: bold;
}

.greeting a:hover {
  color: #7487dc;
}

.greeting b {
  color: #be6069;
}
