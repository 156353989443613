.contact {
  margin-top: 6%;
  padding-bottom: 2%;
  margin-bottom: -2.0%;
  margin-left: -2.0%;
  margin-right: -2.0%;
  display: flex;
 flex-direction: row;
 justify-content: center;
 background-color: lightgray;
}

.contact-box {
  text-align: center;
  display: flex;
  justify-content: center;
  flex-direction: column;
}

.form {
  margin-bottom: 2%;
  text-align: center;
  margin-left: 5%;
}

input {
  margin: 1%;
  width: 50%;
}

textarea {
  width: 50%;
}

.contact p {
  margin-bottom: -0.05%;
}

.contact h1 {
  margin-bottom: -2%;
}

sup {
  color: darkgray;
  margin-bottom: 5%;
}

@media screen and (max-width: 600px) {
  .contact {
    margin-bottom: -5.0%;
    margin-left: -5.0%;
    margin-right: -5.0%;
  }
  input {
    width: 100%;
  }
  textarea {
      width: 100%;
  }
}
