.navbar {
  position: sticky;
  top: 0;
  margin-left: -5.0%;
  margin-right: -5.0%;
  padding-top: 0.5%;
  padding-left: 20%;
  padding-bottom: 1%;
  background-color: #363636;
  color: lightgray;
  font-family: courier, monospace;
}

.terminalText {
  font-size: 75%;
}

.folder {
  color: #4560db;
  text-decoration: none
}

.folder:hover {
  color: #7487dc;
  cursor: pointer;
}

.folder::after {
  color: lightgray;
  content: '/';
}
.name {
  font-size: 300%;
  white-space: no;
}

.nameAnimated {
  display: inline;
}

.nameAnimated::before {
  content: 'Kate';
  font-weight: bold;
  color: #be6069;
}

@media screen and (min-width: 600px) {
  .nameAnimated::before {
    animation-name: nameTransition;
    animation-duration: 30s;
    animation-iteration-count: infinite;
  }
}

@media screen and (max-width: 600px) {
  .name {
    font-size: 200%;
  }
  .navbar {
    margin-top: -5.0%;
    padding-bottom: 3%;
    padding-left: 10%;
    padding-top: 2.5%;
  }
}

.nameAnimated::after {
  margin-left: -0.5%;
  color: lightgray;
  content: '| ';
  animation-name: terminalBlink;
  animation-duration: 2s;
  animation-iteration-count: infinite;
}

.lastName {
  color: white;
  font-weight: bold;
  display: inline;
  margin-left: -2%;
}

.navbar a {
  color: #4560db;
  text-decoration: none
}

.navbar a:hover {
  color: #7487dc;
}

@keyframes nameTransition {
  43% {content: 'Kate'; text-decoration: none;}
  44% {content: 'Kat'; text-decoration: underline dotted red;}
  45% {content: 'Kath'; text-decoration: underline dotted red;}
  46% {content: 'Kathe'; text-decoration: underline dotted red;}
  47% {content: 'Kather'; text-decoration: underline dotted red;}
  48% {content: 'Katheri'; text-decoration: underline dotted red;}
  49% {content: 'Katherin'; text-decoration: underline dotted red;}
  50% {content: 'Katherine'; text-decoration: none;}

  93% {content: 'Katherine'; text-decoration: none;}
  94% {content: 'Katherin'; text-decoration: underline dotted red;}
  95% {content: 'Katheri'; text-decoration: underline dotted red;}
  96% {content: 'Kather'; text-decoration: underline dotted red;}
  97% {content: 'Kathe'; text-decoration: underline dotted red;}
  98% {content: 'Kath'; text-decoration: underline dotted red;}
  99% {content: 'Kat'; text-decoration: underline dotted red;}
  100% {content: 'Kate'; text-decoration: none;}
}

@keyframes terminalBlink {
  50% {color: transparent}
  100% {color: lightgray}
}

.subtitle {
  margin: 0px;
}
